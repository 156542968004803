import consumer from "./consumer"
import $        from "jquery"

$(document).on('turbolinks:load', function() {
  var nextMeetingId = $('#waiting-room-meeting-id').data('next-meeting-id');

  var waitingRoomEventHandler = {
    received(data) {
      switch(data.event) {
        case 'meeting:live':
          window.location.reload();
      }
    }
  }

  consumer.subscriptions.create({"channel": "WaitingRoomsChannel", "next_meeting_id": nextMeetingId }, waitingRoomEventHandler);
})
