import consumer from "./consumer"

// consumer.subscriptions.create({"channel":"ChatChannel","room":"meeting_room"}, {
  // connected() {
    // // Called when the subscription i#{params[:room]}s ready for use on the server
    // console.log('ChatChannel is connected');
  // },

  // disconnected() {
    // // Called when the subscription has been terminated by the server
    // console.log('ChatChannel is disconnected');
  // },

  // received(data) {
    // // Called when there's incoming data on the websocket for this channel
    // console.log('ChatChannel received data');
    // console.log(data);
  // }
// });
