import consumer from "./consumer"
import $        from "jquery"

$(document).on('turbolinks:load', function() {
  var meetingRoomId = $('#owner-meeting-room').data('meeting-id');
  var userType      = $('#owner-meeting-room').data('user-type');

  var ownerEventHandler = {
    received(data) {
      switch(data.event) {
        case 'poll:closed':
          this.refreshOwnerResolutionSection();
          this.refreshOwnerLiveReport();
          break;
        case 'resolution:opened':
        case 'resolution:closed':
        case 'poll:opened':
          this.refreshOwnerResolutionSection();
          break;
        case 'meeting:finished':
          window.location.pathname = '/owner/thank_you';
      }
    },

    refreshOwnerResolutionSection() {
      $.ajax({
        url: "/owner/resolutions/current",
        type: "GET",
        success: function(response) {
          let resolutionSection = document.getElementById("resolution");
          resolutionSection.innerHTML = response;
          let progressBar = document.getElementById("progress-bar");
          if (progressBar) {
            setTimeout(function() {
              progressBar.classList.toggle('countdown');
            }, 600 );
          }
        }
      })
    },

    refreshOwnerLiveReport() {
      $.ajax({
        url: "/owner/resolutions/live_report",
        type: "GET",
        success: function(response) {
          let liveReportSection = document.getElementById("owner-live-reporting");
          liveReportSection.innerHTML = response;
        }
      })
    }
  }

  if (userType === "owner") {
    consumer.subscriptions.create({"channel": "MeetingsChannel", "meeting_id": meetingRoomId }, ownerEventHandler);
    consumer.subscriptions.create({"channel": "MeetingsChannel", "meeting_id": meetingRoomId, "resource_name": "resolutions"}, ownerEventHandler);
    consumer.subscriptions.create({"channel": "MeetingsChannel", "meeting_id": meetingRoomId, "resource_name": "polls"}, ownerEventHandler);
  }
})
