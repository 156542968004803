import $ from "jquery"

const Video = require('twilio-video');

const debugPlease   = true;
const theVideoWidth = 364;

$(document).on('turbolinks:load', function() {
  if(debugPlease) { console.log("video_grid_v2 loaded"); };

  var telco_jwt_token = $('#telco-jwt-token-waiting-room-v2').data('telco-jwt-token');
  if(debugPlease) { console.log("telco JWT token:" + telco_jwt_token); };

  if(telco_jwt_token !== undefined && telco_jwt_token !== '') {
    Video.connect(telco_jwt_token,
                  { video: {
                             width: theVideoWidth,
                             logLevel: 'debug'
                           },
                    audio: true,
                    dominantSpeaker: false
                  })
      .then(room => {
        if(debugPlease) { console.log('Participant connected to Room "%s"', room.name); };

        // displays localTrack on participants's screen
        if(debugPlease) { console.log("LocalParticipant: "+room.localParticipant.identity); };
        displayLocalParticipantVideoTrack(room.localParticipant);

        // // audio and video controls
        // bindLocalAudioControls(room);
        // bindLocalVideoControls(room);
      })
  };
})

function audioTrackToggle(trackPublication) {
  if ( trackPublication.track.isEnabled == true ) {
    audioTrackDisable(trackPublication);
  } else {
    audioTrackEnable(trackPublication);
  }
}

function audioTrackEnable(trackPublication) {
  trackPublication.track.enable();
  $('.audio-controls #mic').removeClass('hidden');
  $('.audio-controls #mic-slash').addClass('hidden');
}

function audioTrackDisable(trackPublication) {
  trackPublication.track.disable();
  $('.audio-controls #mic').addClass('hidden');
  $('.audio-controls #mic-slash').removeClass('hidden');
}

function bindLocalAudioControls(room) {
  $('.audio-controls').on('click', function() {
    room.localParticipant.audioTracks.forEach(audioTrackToggle);
  });
  $('.audio-controls').on('audio_track_enable', function() {
    room.localParticipant.audioTracks.forEach(audioTrackEnable);
  });
  $('.audio-controls').on('audio_track_disable', function() {
    room.localParticipant.audioTracks.forEach(audioTrackDisable);
  });
}

function bindLocalVideoControls(room) {
  $('.video-controls').on('click', function() {
    room.localParticipant.videoTracks.forEach(function(trackPublication) {
      if ( trackPublication.track.isEnabled == true ) {
        trackPublication.track.disable();
        $('.video-controls #camera').addClass('hidden');
        $('.video-controls #camera-slash').removeClass('hidden');
      } else {
        trackPublication.track.enable();
        $('.video-controls #camera').removeClass('hidden');
        $('.video-controls #camera-slash').addClass('hidden');
      }
    });
  });
}

function displayLocalParticipantVideoTrack(participant) {
  if(debugPlease) { console.log('displayLocalParticipantVideoTrack: Displaying participant "%s" video stream', participant.identity); };

  const videoContainer = document.createElement('div');
  videoContainer.id         = participant.sid;
  videoContainer.className += 'video-item local-participant';

  participant.tracks.forEach(publication => {
    videoContainer.appendChild(publication.track.attach());
  });

  // document.getElementById('video-placeholder-v2').style.display = 'none';
  document.getElementById('video-waiting-room-v2').appendChild(videoContainer);
}
