$(document).on('turbolinks:load', function() {
  $('#owners-connection-status-section').on('click','.owner-audio-control', function(e) {
    el = $(e.target);
    ownerId           = el.data('owner-id');
    audioTrackEnabled = el.data('audio-track-enabled');
    meetingId         = el.data('meeting-id');

    $.ajax({
      type:     "PATCH",
      url:      `/managers/meetings/${meetingId}/users/${ownerId}`,
      data:     { user: { audio_track_enabled : audioTrackEnabled } },
      dataType: 'json'
    });
  })
})
