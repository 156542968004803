import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  unmute() {
    if (window.location.href.includes("/managers/")) {
      window.alert("Vous ne pouvez pas ré-activer le micro d'un participant sans son accord. Le participant doit lui-même ré-activer son micro.")
    }
  }

  mute() {
    if (window.location.href.includes("/managers/")) {
      var meetingRoomToken = $('#manager-meeting-room').data('meeting-token');
      var userId           = this.element.id.split("-").slice(-1);

      $.ajax({
        url: `/v2/managers/meetings/${meetingRoomToken}/participants/${userId}/mute`,
        type: "GET"
      })
    }
  }

  self_toggle_mute() {
    if (window.location.href.includes("/participants/")) {
      var meetingRoomToken = $('#participant-meeting-room').data('meeting-token');
      var userId           = $('#participant-meeting-room').data('user-id');
      
      setTimeout(function () {
        var hiddenAudioControlIdAfterClick = $(".audio-controls .hidden")[0].id
        
        if (hiddenAudioControlIdAfterClick == "mic-slash") {
          $.ajax({
            url: `/v2/participants/meetings/${meetingRoomToken}/participants/${userId}/unmute`,
            type: "GET"
          })
        } else if (hiddenAudioControlIdAfterClick == "mic") {
          $.ajax({
            url: `/v2/participants/meetings/${meetingRoomToken}/participants/${userId}/mute`,
            type: "GET"
          })
        }
      }, 500)
    } else if (window.location.href.includes("/managers/")) {
      var meetingRoomToken = $('#manager-meeting-room').data('meeting-token');
      var managerId        = $('#manager-meeting-room').data('user-id');

      setTimeout(function () {
        var hiddenAudioControlIdAfterClick = $(".audio-controls .hidden")[0].id
        
        if (hiddenAudioControlIdAfterClick == "mic-slash") {
          $.ajax({
            url: `/v2/managers/meetings/${meetingRoomToken}/managers/${managerId}/unmute`,
            type: "GET"
          })
        } else if (hiddenAudioControlIdAfterClick == "mic") {
          $.ajax({
            url: `/v2/managers/meetings/${meetingRoomToken}/managers/${managerId}/mute`,
            type: "GET"
          })
        }
      }, 500)
    }
  }
}
