import consumer from "./consumer"
import $        from "jquery"

$(document).on('turbolinks:load', function() {
  var meetingRoomId     = $('#participant-meeting-room').data('meeting-id');
  var meetingRoomUserId = $('#participant-meeting-room').data('user-id');
  var telcoJwtToken     = $('#telco-jwt-token-v2').data('telco-jwt-token');
  var audioButton       = document.querySelectorAll('.audio-controls')[0];

  var participantEventHandler = {
    received(data) {
      switch(data.event) {
        case 'meeting:finished':
          window.location.reload();
          break;

        case 'user:mute':
          var userToMuteId = data.user_id;

          if (userToMuteId == meetingRoomUserId) {
            if(telcoJwtToken !== undefined && telcoJwtToken !== '') {
              audioButton.removeAttribute('data-action');
              audioButton.removeAttribute('data-controller');
              
              setTimeout(function () {
                audioButton.click();
              }, 500)

              setTimeout(function () {
                audioButton.setAttribute('data-controller', 'toggle_mute');
                audioButton.setAttribute('data-action', 'click->toggle_mute#self_toggle_mute');
              }, 500)
            };
          }
          break;

        case 'user:unmute':
          var userToMuteId = data.user_id;

          if (userToMuteId == meetingRoomUserId) {
            if(telcoJwtToken !== undefined && telcoJwtToken !== '') {
              audioButton.removeAttribute('data-action');
              audioButton.removeAttribute('data-controller');
              
              setTimeout(function () {
                audioButton.click();
              }, 500)

              setTimeout(function () {
                audioButton.setAttribute('data-controller', 'toggle_mute');
                audioButton.setAttribute('data-action', 'click->toggle_mute#self_toggle_mute');
              }, 500)
            };
          }
          break;
      }
    }
  }

  consumer.subscriptions.create({'channel': 'V2::MeetingsChannel', 'meeting_id': meetingRoomId }, participantEventHandler);
})
