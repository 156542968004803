$(document).on('turbolinks:load', function() {
  $('#resolution').on('click', ".vote-button", function(e) {
    var voteInFrench = {"for":"pour", "against":"contre", "abstention":"abstention"};
    var voteValue = e.currentTarget.dataset.voteValue;
    $('#vote-confirmation-question').html(`Êtes-vous sûr de vouloir voter ${voteInFrench[voteValue]}?`);
    $('#vote-first-step').toggleClass('d-flex d-none');
    $(`#vote-second-step-${voteValue}`).toggleClass('d-flex d-none');
  })

  $('#resolution').on('click', ".change-vote", function(e) {
    $('#vote-confirmation-question').html("");
    $('#vote-first-step').toggleClass('d-flex d-none');
    $(this.parentNode).toggleClass('d-flex d-none');
  })
})
