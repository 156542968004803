import $ from "jquery"

const Video = require('twilio-video');

const debugPlease   = false;
const theVideoWidth = 180;

$(document).on('turbolinks:load', function() {
  var telco_jwt_token = $('#telco-jwt-token').data('telco-jwt-token');
  if(debugPlease) { console.log("telco JWT token:" + telco_jwt_token); };

  if(telco_jwt_token !== undefined && telco_jwt_token !== '') {
    Video.connect(telco_jwt_token,
                  { video: {
                             width: theVideoWidth,
                             logLevel: 'debug'
                           },
                    audio: true,
                    dominantSpeaker: true
                  })
      .then(room => {
        if(debugPlease) { console.log('Participant connected to Room "%s"', room.name); };

        // displays localTrack on participants's screen
        if(debugPlease) { console.log("LocalParticipant: "+room.localParticipant.identity); };
        displayLocalParticipantVideoTrack(room.localParticipant);

        // displays remoteTracks on participant's screen
        room.participants.forEach(participantConnected);
        room.on('participantConnected', participantConnected);

        room.on('participantDisconnected', participantDisconnected);
        room.once('disconnected', error => room.participants.forEach(participantDisconnected));

        // dominant speaker
        room.on('dominantSpeakerChanged', dominantSpeakerChanged);

        // audio and video controls
        bindLocalAudioControls(room);
        bindLocalVideoControls(room);
      })
  };
})

function audioTrackToggle(trackPublication) {
  if ( trackPublication.track.isEnabled == true ) {
    audioTrackDisable(trackPublication);
  } else {
    audioTrackEnable(trackPublication);
  }
}

function audioTrackEnable(trackPublication) {
  trackPublication.track.enable();
  $('.audio-controls .fa-microphone-slash').removeClass('fa-microphone-slash').addClass('fa-microphone');
}

function audioTrackDisable(trackPublication) {
  trackPublication.track.disable();
  $('.audio-controls .fa-microphone').removeClass('fa-microphone').addClass('fa-microphone-slash');
}

function bindLocalAudioControls(room) {
  $('.audio-controls').on('click', function() {
    room.localParticipant.audioTracks.forEach(audioTrackToggle);
  });
  $('.audio-controls').on('audio_track_enable', function() {
    room.localParticipant.audioTracks.forEach(audioTrackEnable);
  });
  $('.audio-controls').on('audio_track_disable', function() {
    room.localParticipant.audioTracks.forEach(audioTrackDisable);
  });
}

function bindLocalVideoControls(room) {
  $('.video-controls').on('click', function() {
    room.localParticipant.videoTracks.forEach(function(trackPublication) {
      if ( trackPublication.track.isEnabled == true ) {
        trackPublication.track.disable();
        $('.video-controls .fa-video').removeClass('fa-video').addClass('fa-video-slash');
      } else {
        trackPublication.track.enable();
        $('.video-controls .fa-video-slash').removeClass('fa-video-slash').addClass('fa-video');
      }
    });
  });
}

function dominantSpeakerChanged(participant) {
  if(debugPlease) { console.log('dominantSpeakerChanged: The new dominant speaker in the Room is:', participant.identity); };

  $('.dominant-speaker').removeClass('dominant-speaker');
  document.getElementById(participant.sid).className += ' dominant-speaker'
}

function displayLocalParticipantVideoTrack(participant) {
  if(debugPlease) { console.log('displayLocalParticipantVideoTrack: Displaying participant "%s" video stream', participant.identity); };
  var userIsManager    = participant.identity.match(/^MANAGER.*/);
  const videoContainer = document.createElement('div');
  videoContainer.id         = participant.sid;
  videoContainer.className += 'video-item local-participant';

  const nameSubTitle = document.createElement('span');
  if(userIsManager === null) {
    nameSubTitle.innerText = shortIdentity(participant.identity);
  } else {
    nameSubTitle.innerText = branchName(participant.identity);
  }
  nameSubTitle.className += 'participant-name-caption';
  videoContainer.appendChild(nameSubTitle);

  participant.tracks.forEach(publication => {
    videoContainer.appendChild(publication.track.attach());
  });

  document.getElementById('video-placeholder').style.display = 'none';
  document.getElementById('video-grid').appendChild(videoContainer);
}

function participantConnected(participant) {
  if(debugPlease) { console.log('participantConnected: Participant "%s" connected', participant.identity); };
  var userIsManager = participant.identity.match(/^MANAGER.*/);
  if(userIsManager === null) {
    // User is not a MANAGER, therefore he is an OWNER
    if(debugPlease) { console.log('participantConnected: Participant is NOT a MANAGER'); };
  } else {
    // User is a MANAGER
    if(debugPlease) { console.log('participantConnected: Participant is a MANAGER'); };
  }

  const videoContainer = document.createElement('div');
  videoContainer.id         = participant.sid;
  videoContainer.className += 'video-item remote-participant';

  const nameSubTitle = document.createElement('span');
  if(userIsManager === null) {
    nameSubTitle.innerText = shortIdentity(participant.identity);
  } else {
    nameSubTitle.innerText = branchName(participant.identity);
  }
  nameSubTitle.className += 'participant-name-caption';
  videoContainer.appendChild(nameSubTitle);

  participant.on('trackSubscribed', track => trackSubscribed(videoContainer, track));
  participant.on('trackUnsubscribed', trackUnsubscribed);

  participant.tracks.forEach(publication => {
    if (publication.isSubscribed) {
      trackSubscribed(videoContainer, publication.track);
    }
  });

  document.getElementById('video-grid').appendChild(videoContainer);
}

function participantDisconnected(participant) {
  if(debugPlease) { console.log('Participant "%s" disconnected', participant.identity); };
  document.getElementById(participant.sid).remove();
}

function trackSubscribed(div, track) {
  div.appendChild(track.attach());
}

function trackUnsubscribed(track) {
  track.detach().forEach(element => element.remove());
}

function shortIdentity(identity) {
  const regex = /\[(.*)\]/g;
  const name  = identity.match(regex);
  return name;
}

function branchName(identity) {
  var array       = identity.split(' / ')
  var lastElement = array[array.length - 1]
  if(lastElement === '') { 
    return "Manager" 
  } else {
    return lastElement
  }
}
