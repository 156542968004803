import Sortable from "sortablejs";

$(document).on('turbolinks:load', function() {
  initializeSortable();

  var resolutionSection = document.getElementById('resolution-section');
  if (resolutionSection) {
    resolutionSection.addEventListener('resolutionSectionRefreshed', function(e) {
      initializeSortable();
    })
  }

  function initializeSortable() {
    var pollList = document.getElementById('sortable-polls');
    if (pollList) {
      Sortable.create(pollList, {
        onEnd: function (event) {
          let id = event.item.dataset.id
          let newPosition = event.newIndex + 1

          $.ajax({
            url: `/managers/polls/${id}`,
            type: 'PATCH',
            data: { poll: { position: newPosition } },
            success: function(response) {
              document.getElementById('meeting-buttons').innerHTML = response;
            }
          })
        }
      })
    }
  }
})
