$(document).on('turbolinks:load', function() {
  if (document.getElementById("timeup-counter")) {
    var hoursSpan       = document.getElementById("hours");
    var minutesSpan     = document.getElementById("minutes");
    var secondsSpan     = document.getElementById("seconds");
    var meetingDuration = document.getElementById('meeting-duration').dataset.meetingDuration;
    var totalSeconds    = meetingDuration;
    setInterval(setTime, 1000);
  }

  if (document.getElementById("timeup-counter-v2")) {
    var hoursSpan       = document.getElementById("hours");
    var minutesSpan     = document.getElementById("minutes");
    var secondsSpan     = document.getElementById("seconds");
    var meetingDuration = document.getElementById('meeting-duration').dataset.meetingDuration;
    var totalSeconds    = meetingDuration;
    setInterval(setTime, 1000);
  }

  function setTime() {
    ++totalSeconds;
    secondsSpan.innerHTML = pad(totalSeconds%60);
    minutesSpan.innerHTML = pad(parseInt((totalSeconds/60)%60));
    hoursSpan.innerHTML   = pad(parseInt(totalSeconds/3600));
  }

  function pad(val) {
    var valString = val + "";
    return ((valString.length < 2) ? ("0" + valString) : valString);
  }
})

