import consumer from "./consumer"
import $        from "jquery"

$(document).on('turbolinks:load', function() {
  var meetingId = $('#waiting-room-meeting-id').data('meeting-id');

  var waitingRoomEventHandler = {
    received(data) {
      switch(data.event) {
        case 'meeting:live':
          window.location.reload();
      }
    }
  }

  consumer.subscriptions.create({'channel': 'V2::WaitingRoomsChannel', 'meeting_id': meetingId }, waitingRoomEventHandler);
})
