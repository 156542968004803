import consumer from "./consumer"
import $        from "jquery"

$(document).on('turbolinks:load', function() {
  var meetingRoomId = $('#manager-subscription').data('meeting-id');
  var userType      = $('#manager-subscription').data('user-type');

  var managerEventHandler = {
    received(data) {
      switch(data.event) {
        case 'ballot:created':
          this.refreshLiveReportSection(data)
          break;
        case 'connection_event:created':
        case 'user:updated':
          this.refreshOwnersConnectionStatusSection(data)
      }
    },

    refreshLiveReportSection(data) {
      $.ajax({
        url: `/managers/polls/${data.poll_id}/live_reporting`,
        type: "GET",
        success: function(response) {
          let liveReportSection = document.getElementById("manager-live-report");
          liveReportSection.innerHTML = response;
        }
      })
    },

    refreshOwnersConnectionStatusSection(data) {
      $.ajax({
        url: `/managers/meetings/${meetingRoomId}/connection_events`,
        type: "GET",
        success: function(response) {
          let connectionStatusesSection = document.getElementById("owners-connection-status-section");
          connectionStatusesSection.innerHTML = response;
        }
      })
    }
  }

  if (userType === "manager") {
    consumer.subscriptions.create({"channel": "MeetingsChannel", "meeting_id": meetingRoomId, "resource_name": "ballots"},           managerEventHandler)
    consumer.subscriptions.create({"channel": "MeetingsChannel", "meeting_id": meetingRoomId, "resource_name": "connection_events"}, managerEventHandler)
    consumer.subscriptions.create({"channel": "MeetingsChannel", "meeting_id": meetingRoomId, "resource_name": "users"},             managerEventHandler)
  }
})
