$(document).on('turbolinks:load', function() {
  $('#resolution-section').on('click','.closed-poll, .opened-poll', function(e) {
    var pollId = e.currentTarget.dataset.id;

    $.ajax({
      type:    "GET",
      url:     `/managers/polls/${pollId}/results`,
      success: function(response) {
        document.getElementById('manager-live-report').innerHTML = response;
      }
    });
  })
})
