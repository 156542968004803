import consumer from "./consumer"

consumer.subscriptions.create("UsersChannel", {
  received(data) {
    if( data.audio_track_enabled == true ) {
      $('.audio-controls').trigger('audio_track_enable');
    } else {
      $('.audio-controls').trigger('audio_track_disable');
    }
  }
});
