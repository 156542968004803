$(document).on('turbolinks:load', function() {
  if (document.getElementById("owner-meeting-room")) {
    window.addEventListener('beforeunload', function(e) {
      $.ajax({
        url: `/owner/connection_events`,
        type: 'POST',
        data: { connection_event: { meeting_id: $('#owner-meeting-room').data('meeting-id') } }
      })
    });
  }
})
