import consumer from "./consumer"
import $        from "jquery"

$(document).on('turbolinks:load', function() {
  var meetingRoomId = $('#manager-meeting-room').data('meeting-id');

  var managerEventHandler = {
    received(data) {
      switch(data.event) {
        case 'meeting:live':
        case 'meeting:finished':
          window.location.reload();
      }
    }
  }

  consumer.subscriptions.create({'channel': 'V2::MeetingsChannel', 'meeting_id': meetingRoomId }, managerEventHandler);
})
